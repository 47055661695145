import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-inline-button, [inline-button]',
  templateUrl: './inline-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'inline-button',

    'inline-flex',
    'transparent',
    'on-surface',

    'w-fit',
    'border-none',

    'corner-full',

    'hover:text-primary',
    'disabled:text-opacity-disabled',
  ];

  @HostBinding('attr.data-has-leading-icon')
  @Input()
  leadingIcon: string;

  @HostBinding('attr.data-has-animated-icon')
  @Input()
  iconAnimation: string;

  @HostBinding('attr.data-is-animating')
  @Input()
  processing: boolean;

  @HostBinding('attr.data-has-trailing-icon')
  @Input()
  trailingIcon: string;
}
