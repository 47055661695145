import { Subject } from 'rxjs';
import { isSelectableKey } from './keyboard';

export interface SelectionChange<T> {
  /** Model that dispatched the event. */
  source: SelectionModel<T>;
  /** Options that are currently selected. */
  selected: T[];
  /** Options that were added to the model. */
  added: T[];
  /** Options that were removed from the model. */
  removed: T[];
}

export class SelectionModel<T> {
  private _selection = new Set<T>();
  private _deselectedToEmit: T[] = [];
  private _selectedToEmit: T[] = [];
  private _selected: T[] | null;

  get selected(): T[] {
    if (!this._selected) {
      this._selected = Array.from(this._selection.values());
    }

    return this._selected;
  }

  readonly changed = new Subject<SelectionChange<T>>();

  constructor(
    protected multiple = false,
    initiallySelectedValues?: T[],
    private _emitChanges = true,
    public compareWith: (o1: T, o2: T) => boolean = (o1, o2) => o1 === o2
  ) {
    if (initiallySelectedValues && initiallySelectedValues.length) {
      if (multiple) {
        initiallySelectedValues.forEach(this._onSelect.bind(this));
      } else {
        this._onSelect(initiallySelectedValues[0]);
      }
    }
    this._selectedToEmit.length = 0;
  }

  select(...values: T[]): boolean {
    this._validate(values);
    values.forEach(this._onSelect.bind(this));
    const changed = this._hasChanges();
    this._emitChangeEvent();

    return changed;
  }

  deselect(...values: T[]): boolean {
    this._validate(values);
    const selectionValues = this.selected.filter((selected) =>
      values.some((value) => this.compareWith(selected, value))
    );
    selectionValues.forEach(this._onDeselect.bind(this));
    const changed = this._hasChanges();
    this._emitChangeEvent();
    return changed;
  }

  setSelection(...values: T[]): boolean {
    this._validate(values);
    const oldValues = this.selected;
    const newSelectedSet = new Set(values);
    values.forEach(this._onSelect.bind(this));
    oldValues.filter((value) => !newSelectedSet.has(value)).forEach(this._onDeselect.bind(this));
    const changed = this._hasChanges();
    this._emitChangeEvent();

    return changed;
  }

  setRawSelection(...values: any[]): boolean {
    this._validate(values);

    values.forEach(this._onRawSelect.bind(this));
    const changed = this._hasChanges();
    this._emitChangeEvent();
    return changed;
  }

  setRawDeselection(...values: any[]): boolean {
    this._validate(values);

    values.forEach(this._onRawDeselect.bind(this));
    const changed = this._hasChanges();
    this._emitChangeEvent();
    return changed;
  }

  clear(emit = true): boolean {
    this._deselectAll();
    const changed = this._hasChanges();

    if (emit) {
      this._emitChangeEvent();
    }
    return changed;
  }

  onKeydown(option: T, event: KeyboardEvent) {
    const key = event.code;

    if (event.defaultPrevented || !isSelectableKey(key)) {
      return;
    }

    if (this.isSelected(option)) {
      this.deselect(option);
    } else {
      this.select(option);
    }
    event.preventDefault();
  }

  isSelected(value: T): boolean {
    return (this._selected || []).some((selected) => this.compareWith(selected, value));
  }

  isEmpty(): boolean {
    return this._selection.size === 0;
  }

  hasValue(): boolean {
    return !this.isEmpty();
  }

  sort(predicate?: (a: T, b: T) => number): void {
    if (this.multiple && this.selected) {
      this._selected.sort(predicate);
    }
  }

  isMultipleSelection() {
    return this.multiple;
  }

  private _onSelect(value: T) {
    if (!this.isSelected(value)) {
      if (!this.multiple) {
        this._deselectAll();
      }

      this._selection.add(value);

      if (this._emitChanges) {
        this._selectedToEmit.push(value);
      }
    }
  }

  private _onRawSelect(value: any) {
    if (!this.isSelected(value)) {
      this._selection.add(value);
    }
    if (this._emitChanges) {
      this._selectedToEmit.push(value);
    }
  }

  private _onRawDeselect(value: any) {
    if (this.isSelected(value)) {
      this._selection.delete(value);
    }
    if (this._emitChanges) {
      this._deselectedToEmit.push(value);
    }
  }

  private _onDeselect(value: T) {
    if (this.isSelected(value)) {
      this._selection.delete(value);

      if (this._emitChanges) {
        this._deselectedToEmit.push(value);
      }
    }
  }

  private _deselectAll() {
    if (!this.isEmpty()) {
      this._selection.forEach((value) => this._onDeselect(value));
    }
  }

  private _emitChangeEvent() {
    this._selected = null;

    if (this._selectedToEmit.length || this._deselectedToEmit.length) {
      this.changed.next({
        source: this,
        selected: this.selected,
        added: this._selectedToEmit,
        removed: this._deselectedToEmit,
      });

      this._deselectedToEmit = [];
      this._selectedToEmit = [];
    }
  }

  private _validate(values: T[]) {
    if (values.length > 1 && !this.multiple) {
      throw Error('Cannot activate multiple values in single selection mode');
    }
  }

  private _hasChanges(): boolean {
    return [this._selectedToEmit.length, this._deselectedToEmit.length].some(Boolean);
  }
}
